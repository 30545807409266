import styled from '@emotion/styled'
import { Dimension, User } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  pax?: string
  size?: string
}

export const Info = memo(function Info({ pax, size }: Props) {
  if (!pax || !size) {
    return null
  }

  return (
    <Container dial={5} row wrap>
      {size ? (
        <Item>
          <Dimension />

          {size}
        </Item>
      ) : null}

      {pax ? (
        <Item>
          <User />

          {pax}
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  @media (max-width: 1199px) {
    justify-content: flex-start;
    margin-top: 0.75rem;
  }
`

const Item = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 0 3.75rem;

  svg {
    display: block;
    width: auto;
    height: 3.75rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark};
    margin-bottom: 0.75rem;
  }

  @media (max-width: 1199px) {
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin: 0 0.75rem 0 0;

    svg {
      display: none;
    }
  }
`
