import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      scale: 1,
    },
    hidden: {
      opacity: 0,
      scale: 0,
    },
  }

  return (
    <Container>
      <Wrapper
        ref={ref}
        animate={isVisible ? 'visible' : 'hidden'}
        transition={{
          duration: 0.5,
        }}
        variants={variants}
      >
        {icon ? <Icon src={icon} alt={label} width="40" height="40" /> : null}

        {label ? <Label>{label}</Label> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 33.333%;
  aspect-ratio: 1.5;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  text-align: center;
  &:nth-of-type(even) {
    background: ${({ theme }) => theme.colors.variants.primaryDark};
  }

  @media (max-width: 1199px) {
    width: 50%;
    aspect-ratio: 0;
    padding: 1.875rem;
  }
`

const Wrapper = styled(motion.div)``

const Icon = styled.img`
  width: auto;
  height: 2.5rem;
  margin-bottom: 1.3125rem;

  @media (max-width: 1199px) {
    height: 1.875rem;
    margin-bottom: 0.75rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.6875rem;

  @media (max-width: 1199px) {
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 0.875rem;
  }
`
