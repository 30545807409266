import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  title?: string
}

export const ServicesList = memo(function ServicesList({
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container row tag="section" stretch wrap>
      <LeftSide>
        {title ? (
          <FadeInUp>
            <Title className="wave-light">{title}</Title>
          </FadeInUp>
        ) : null}
      </LeftSide>

      <RightSide row stretch wrap>
        {services.map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: 25%;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  padding: 3.75rem;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 2.5rem 1.875rem;
  }
`

const Title = styled.h2`
  max-width: 8.75rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.125rem;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`

const RightSide = styled(FlexBox)`
  width: 75%;

  @media (max-width: 1199px) {
    width: 100%;
  }
`
