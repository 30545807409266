import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  images: ImageProps[]
}

export const Gallery = memo(function Gallery({ images }: Props) {
  if (!images) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1800,
    },
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
    breakpoints: {
      '(max-width: 1199px)': {
        defaultAnimation: {
          duration: 300,
        },
        slides: {
          perView: 1,
          spacing: 6,
        },
      },
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <Slider className="grab-cursor" ref={sliderRef}>
        {uniq(images).map((item, index) => (
          <Slide key={index} className="keen-slider__slide">
            <Inner style={positionStyle(index)}>
              <Image
                alt={loaded[index] || nextLoaded[index] ? item.alt : ''}
                animationStart={
                  loaded[index] || nextLoaded[index] ? true : false
                }
                {...(loaded[index] || nextLoaded[index] ? item : null)}
              />

              <Spinner />
            </Inner>
          </Slide>
        ))}
      </Slider>

      {images.length > 1 ? (
        <Arrows dial={5} row>
          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
          />

          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
          />
        </Arrows>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  height: calc(100% - 5.625rem);
  overflow: hidden;
  position: relative;

  @media (max-width: 1199px) {
    height: 100%;
    padding: 1.875rem 1.875rem 0;
  }
`

const Slider = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  outline: none;
  overflow: hidden;

  @media (max-width: 1199px) {
    overflow: visible;
  }
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);

  @media (max-width: 1199px) {
    transform: none !important;
  }
`

const Arrows = styled(FlexBox)`
  width: 100%;
  position: absolute;
  bottom: 3rem;
  left: 0;

  @media (max-width: 1199px) {
    display: none;
  }
`
